'use client';
import { useLocaleCtx } from '@/contexts/locale';
import NextLink from 'next/link';
import React from 'react';

interface Props extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  href: string;
  children?: React.ReactNode;
  scroll?: boolean;
}

const Link = React.forwardRef<HTMLAnchorElement, Props>(
  ({ children, scroll, href, ...props }, ref) => {
    const { lang } = useLocaleCtx();

    return (
      <NextLink scroll={scroll} ref={ref} href={`/${lang}${href}`} locale={lang} {...props}>
        {children && children}
      </NextLink>
    );
  }
);

export default Link;
