import { MetaData } from '@/app/api/utils/types';
import { getSession } from './local-services';

export type ApiResponse<T> = {
  data: T;
  message: string;
  meta?: MetaData['pagination'];
};

/**
 * a simple fetch handler to handle http requests
 */
export async function localFetch<T>(url: string, options?: RequestInit): Promise<ApiResponse<T>> {
  const res = await fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getSession()}`,
      ...options?.headers,
    },
  });

  if (!res.ok) {
    const errMsg = await res.json();

    throw Error(errMsg?.message ?? 'An error occurred. Please try again.');
  }

  return await res.json();
}
