export const IMAGE_URL =
  process.env.NEXT_PUBLIC_IMAGE_URL ?? 'https://golden-pen-cms-staging.uxbert.rocks';

/*
 * this is the cookie name that will be used to store the voting state
 * of the user. This cookie will be used to check if the user has voted
 */
export const COOKIE_HAS_VOTED = '__gp-UXVID';

export const ENV = {
  RECAPTCHA_SECRET_KEY: process.env.RECAPTCHA_SECRET_KEY,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  ENABLE_RECATCHA: process.env.ENABLE_RECATCHA || 'false',
  NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
} as const;
