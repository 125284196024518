'use client';
// import { Button } from '@/components/ui/button';
import { Shell } from '@/components/ui/shell';
import { useLocaleCtx } from '@/contexts/locale';
import useScroll from '@/hooks/use-scroll';
import { useTranslation } from '@/i18n/client';
import { cn } from '@/lib/utils';
import { usePathname } from 'next/navigation';
import LOGO_DARK from '~/public/assets/sponsors/logo-dark.png';
import Link from '../Link/link';
import Logo from '../logo';
import MobileNavbar from './mobile-nav';
import { Routes } from './routes';

export default function Navbar() {
  const { lang, isChangingLang, onLangChange, inactiveLang } = useLocaleCtx();

  const { t } = useTranslation(lang, 'navbar');

  const scrolled = useScroll(100);

  const pathname = usePathname();
  const isJoinPage = Boolean(['/join', '/terms', '/voting'].find((x) => pathname.includes(x)));

  const routes = t('routes', { returnObjects: true }) as Routes[];

  return (
    <div
      className={cn(
        'sticky top-0 z-[900]',
        scrolled && 'backdrop-blur-xl bg-background/20',
        isJoinPage && 'bg-primary'
      )}
    >
      <Shell as={'nav'} className='py-2'>
        <div className={cn('flex items-center w-full justify-between')}>
          <Link href={'/'}>
            <Logo src={isJoinPage ? LOGO_DARK : undefined} />
          </Link>

          <div className='block md:hidden'>
            <MobileNavbar
              routes={routes}
              lang={lang}
              isChangingLang={isChangingLang}
              onLangChange={onLangChange}
              inactiveLang={inactiveLang}
              className={!isJoinPage ? 'bg-background' : ''}
            />
          </div>

          <ul className={cn('items-center md:flex hidden gap-6 lg:gap-8')}>
            {routes.map((route) => (
              <li key={route.name}>
                <Link
                  className='hover:border-foreground border-b-2 border-transparent transition-all ease-in duration-150'
                  href={route.href}
                >
                  {route.name}
                </Link>
              </li>
            ))}
          </ul>

          {/* <Button
            variant={'ghost'}
            isLoading={isChangingLang}
            onClick={onLangChange}
            className='!text-sm hidden md:flex !font-normal hover:bg-transparent'
          >
            {inactiveLang}
          </Button> */}
        </div>
      </Shell>
    </div>
  );
}
